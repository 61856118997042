import React from 'react';

import { disclaimer, inner, text, withUnderlay } from './serving-disclaimer.module.scss';

interface IServingDisclaimerProps {
    className?: string;
    isWithUnderlay?: boolean;
}

const ServingDisclaimer: React.FC<IServingDisclaimerProps> = ({
    className = '',
    isWithUnderlay = false,
}) => {
    return (
        <p className={`${disclaimer} ${className} ${isWithUnderlay ? withUnderlay : ''}`}>
            <span className={inner}>
                <span className={text}>Propozycja podania</span>
            </span>
        </p>
    );
};

export default ServingDisclaimer;
