// extracted by mini-css-extract-plugin
export var apLink = "footer-module--ap-link--cf884";
export var backgroundAnimation = "footer-module--backgroundAnimation--aeb8b";
export var bottom = "footer-module--bottom--de116";
export var container = "footer-module--container--f5ab3";
export var created = "footer-module--created--3706f";
export var errorBlink = "footer-module--error-blink--cc886";
export var logo = "footer-module--logo--aa179";
export var social = "footer-module--social--342a2";
export var socials = "footer-module--socials--78a89";
export var top = "footer-module--top--7a5da";