import React from 'react';

import { button, dark } from './button.module.scss';

import { InteractiveElement } from '../../models/interactive-element.model';
import { IUseButton, useButton } from '../../hooks/use-button';

type TButtonColor = 'light' | 'dark';

export interface IButtonProps extends Omit<IUseButton, 'as'> {
    as?: InteractiveElement;
    className?: string;
    color?: TButtonColor;
}

const Button: React.FC<IButtonProps & Record<string, any>> = ({
    as = 'button',
    onClick = () => {},
    to = '',
    className = '',
    children,
    params = null,
    type = '',
    isLoading = false,
    color = 'light',
    ...rest
}) => {
    const { ButtonTag, buttonProps } = useButton({ as, to, params, onClick, type });
    const colorClass = colorClasses[color];

    return (
        <ButtonTag className={`${button} ${colorClass} ${className}`} {...buttonProps} {...rest}>
            {children}
        </ButtonTag>
    );
};

const colorClasses: Record<TButtonColor, string> = {
    light: '',
    dark: dark,
};

export default Button;
