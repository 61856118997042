// extracted by mini-css-extract-plugin
export var backgroundAnimation = "inspiration-module--backgroundAnimation--4e31a";
export var backlight = "inspiration-module--backlight--b18e2";
export var caramelSplash = "inspiration-module--caramel-splash--9cc03";
export var chocolate = "inspiration-module--chocolate--fad61";
export var container = "inspiration-module--container--b0c82";
export var errorBlink = "inspiration-module--error-blink--dc09b";
export var header = "inspiration-module--header--f56f2";
export var imageBox = "inspiration-module--image-box--e5351";
export var inner = "inspiration-module--inner--db55c";
export var title = "inspiration-module--title--72984";