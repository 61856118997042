// extracted by mini-css-extract-plugin
export var backgroundAnimation = "product-module--backgroundAnimation--b17e3";
export var container = "product-module--container--91e75";
export var content = "product-module--content--52e2a";
export var details = "product-module--details--9954f";
export var errorBlink = "product-module--error-blink--50589";
export var icon = "product-module--icon--51404";
export var imageBox = "product-module--image-box--ba365";
export var inner = "product-module--inner--f4c7e";
export var name = "product-module--name--533be";
export var nameBox = "product-module--name-box--c9639";
export var reverse = "product-module--reverse--d6db9";
export var serving = "product-module--serving--83bce";
export var spec = "product-module--spec--b7c9f";
export var subName = "product-module--sub-name--73e31";