// extracted by mini-css-extract-plugin
export var backgroundAnimation = "template-module--backgroundAnimation--05adb";
export var caramelServing = "template-module--caramel-serving--23841";
export var chocolateImage = "template-module--chocolate-image--08d32";
export var container = "template-module--container--0e404";
export var errorBlink = "template-module--error-blink--19fce";
export var inspiration = "template-module--inspiration--08d1d";
export var marquee = "template-module--marquee--ff6e7";
export var recipesSection = "template-module--recipes-section--1d376";
export var serving = "template-module--serving--2a973";
export var wedelLogo = "template-module--wedel-logo--df245";