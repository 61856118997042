// extracted by mini-css-extract-plugin
export var backgroundAnimation = "interlude-module--backgroundAnimation--2c749";
export var caramel01 = "interlude-module--caramel-01--0ef96";
export var caramel02 = "interlude-module--caramel-02--d7358";
export var caramelSplash = "interlude-module--caramel-splash--ecb3e";
export var chocolate = "interlude-module--chocolate--3c29b";
export var chocolateSplash = "interlude-module--chocolate-splash--92c3b";
export var container = "interlude-module--container--3a554";
export var errorBlink = "interlude-module--error-blink--070bc";
export var icon = "interlude-module--icon--f39ac";
export var imageBox = "interlude-module--image-box--5a821";
export var title = "interlude-module--title--7454d";
export var titleBox = "interlude-module--title-box--4c307";