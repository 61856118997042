// extracted by mini-css-extract-plugin
export var backgroundAnimation = "hero-module--backgroundAnimation--27ab7";
export var caramel = "hero-module--caramel--ce970";
export var chocolate = "hero-module--chocolate--b2d39";
export var container = "hero-module--container--997cd";
export var content = "hero-module--content--97795";
export var errorBlink = "hero-module--error-blink--d001b";
export var icon = "hero-module--icon--1bbea";
export var image = "hero-module--image--c90d5";
export var imageBox = "hero-module--image-box--0b80b";
export var inner = "hero-module--inner--ce907";
export var ratio = "hero-module--ratio--db29f";
export var serving = "hero-module--serving--1dd1e";
export var title = "hero-module--title--23d49";