import React from 'react';

import {
    container,
    inner,
    imageBox,
    content,
    nameBox,
    name,
    subName,
    details,
    serving,
    icon,
    spec,
    reverse,
} from './product.module.scss';
import { grid } from '../../styles/grid.module.scss';
import DrinkIcon from '../../assets/images/soplica-wedel/svg/drink.svg';

export interface IProductWedel {
    name: string;
    subName: React.ReactNode;
    description: string;
    serving: string;
    capacity: string;
    voltage: string;
    imgSrc: string;
}

interface IProductProps {
    className?: string;
    id?: string;
    product: IProductWedel;
    imageClassName?: string;
    isReverse?: boolean;
    children?: React.ReactNode;
}

const Product: React.FC<IProductProps> = ({
    className = '',
    product,
    imageClassName = '',
    isReverse = false,
    id,
    children,
}) => {
    return (
        <div id={id} className={`${container} ${grid} ${className} ${isReverse ? reverse : ''}`}>
            <div className={inner}>
                <div className={`${imageBox} ${imageClassName}`}>
                    <img src={product.imgSrc} alt={product.name} />
                    {children}
                </div>
                <div className={content}>
                    <h2 className={nameBox}>
                        <span className={name}>{product.name}</span>
                        <span className={subName}>{product.subName}</span>
                    </h2>
                    <div className={details}>
                        <p>{product.description}</p>
                        <div className={serving}>
                            <DrinkIcon className={icon} />
                            <p>{product.serving}</p>
                        </div>
                        <div className={spec}>
                            <p>Pojemność: {product.capacity}</p>
                            <p>Zawartość alkoholu: {product.voltage}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;
