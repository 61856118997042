// extracted by mini-css-extract-plugin
export var backgroundAnimation = "recipe-card-module--backgroundAnimation--9ff48";
export var button = "recipe-card-module--button--700aa";
export var caramelSplash01 = "recipe-card-module--caramel-splash-01--289e6";
export var caramelSplash02 = "recipe-card-module--caramel-splash-02--6dab3";
export var closeButton = "recipe-card-module--close-button--37f98";
export var closeIcon = "recipe-card-module--close-icon--cb13a";
export var container = "recipe-card-module--container--a8dbe";
export var content = "recipe-card-module--content--83552";
export var details = "recipe-card-module--details--2ecd8";
export var errorBlink = "recipe-card-module--error-blink--b8e41";
export var image = "recipe-card-module--image--415f4";
export var ingredients = "recipe-card-module--ingredients--73a98";
export var ingredientsLabel = "recipe-card-module--ingredients-label--40ef6";
export var layerButton = "recipe-card-module--layer-button--caf71";
export var modal = "recipe-card-module--modal--cf639";
export var modalContainer = "recipe-card-module--modal-container--97307";
export var modalContent = "recipe-card-module--modal-content--3c5ce";
export var modalGrid = "recipe-card-module--modal-grid--8319c";
export var modalName = "recipe-card-module--modal-name--bcc75";
export var name = "recipe-card-module--name--fac93";
export var ratio = "recipe-card-module--ratio--dc44b";
export var typeName = "recipe-card-module--type-name--2206b";