import React, { useRef, useEffect, useState } from 'react';

import { container, item, animate } from './marquee.module.scss';

const SPEED_FACTOR = 30;
const RESIZE_ANIMATION_DELAY = 1000;

interface IMarqueeProps {
    className?: string;
    children?: React.ReactNode;
}

const Marquee: React.FC<IMarqueeProps> = ({ className = '', children }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const itemRef = useRef<HTMLDivElement | null>(null);
    const [multiplier, setMultiplier] = useState(2);
    const [itemWidth, setItemWidth] = useState(0);
    const [canAnimate, setCanAnimate] = useState(false);

    useEffect(() => {
        const calculateMultiplier = () => {
            setCanAnimate(false);
            const containerEl = containerRef.current;
            const marqueeEl = itemRef.current;
            if (!containerEl || !marqueeEl || !children) return;
            setMultiplier(Math.ceil(containerEl.clientWidth / marqueeEl.clientWidth) + 1);
            setItemWidth(marqueeEl.clientWidth);
            setTimeout(() => setCanAnimate(true), RESIZE_ANIMATION_DELAY);
        };
        calculateMultiplier();
        window.addEventListener('resize', calculateMultiplier);
        return () => {
            window.removeEventListener('resize', calculateMultiplier);
        };
    }, [children]);

    return (
        <div
            ref={containerRef}
            className={`${container} ${className} ${canAnimate ? animate : ''}`}
            style={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                '--marquee-item-width': `${itemWidth}px`,
                '--marquee-speed': `${itemWidth * SPEED_FACTOR}ms`,
            }}
        >
            {[...Array(multiplier).keys()].map((num) => {
                return (
                    <div
                        className={item}
                        ref={num === 0 ? itemRef : undefined}
                        key={`marquee-${num}`}
                    >
                        {children}
                    </div>
                );
            })}
        </div>
    );
};

export default Marquee;
