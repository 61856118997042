// extracted by mini-css-extract-plugin
export var backgroundAnimation = "recipes-module--backgroundAnimation--2213d";
export var backlight = "recipes-module--backlight--ea3e3";
export var caramel = "recipes-module--caramel--96ca3";
export var caramelSplash = "recipes-module--caramel-splash--5d77a";
export var card = "recipes-module--card--75d65";
export var chocolateSplash01 = "recipes-module--chocolate-splash-01--84cb6";
export var chocolateSplash02 = "recipes-module--chocolate-splash-02--c8ecc";
export var container = "recipes-module--container--2d71d";
export var errorBlink = "recipes-module--error-blink--1cdb4";
export var header = "recipes-module--header--67ed6";
export var inner = "recipes-module--inner--c41b4";
export var item = "recipes-module--item--7922e";
export var list = "recipes-module--list--fddba";
export var title = "recipes-module--title--4b6bd";