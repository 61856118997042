import React from 'react';

import { container, item, chocolate, cocoa, chocolateBar } from './marquee-item.module.scss';
import ChocolateIcon from '../../assets/images/soplica-wedel/svg/chocolate.svg';
import CocoaIcon from '../../assets/images/soplica-wedel/svg/cocoa.svg';
import ChocolateBarIcon from '../../assets/images/soplica-wedel/svg/chocolate-bar.svg';

interface IMarqueeItemProps {
    className?: string;
}

const MarqueeItem: React.FC<IMarqueeItemProps> = ({ className = '' }) => {
    return (
        <div className={`${container} ${className}`}>
            <div className={item}>
                <p>Jakość</p>
                <ChocolateIcon className={chocolate} />
            </div>
            <div className={item}>
                <p>Smak</p>
                <CocoaIcon className={cocoa} />
            </div>
            <div className={item}>
                <p>Przyjemność</p>
                <ChocolateBarIcon className={chocolateBar} />
            </div>
        </div>
    );
};

export default MarqueeItem;
