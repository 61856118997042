// extracted by mini-css-extract-plugin
export var backgroundAnimation = "serving-module--backgroundAnimation--a1725";
export var box = "serving-module--box--75991";
export var button = "serving-module--button--878fc";
export var caramelSplash = "serving-module--caramel-splash--5e5e9";
export var chocolate = "serving-module--chocolate--ece58";
export var chocolateSplash = "serving-module--chocolate-splash--c9392";
export var container = "serving-module--container--ce940";
export var errorBlink = "serving-module--error-blink--a8aee";
export var header = "serving-module--header--7076b";
export var inner = "serving-module--inner--98edc";
export var navigation = "serving-module--navigation--bba10";
export var next = "serving-module--next--d5ffd";
export var prev = "serving-module--prev--09484";
export var sliderBox = "serving-module--slider-box--57fa9";
export var title = "serving-module--title--c52ce";