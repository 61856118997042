import React from 'react';

import {
    container,
    header,
    title,
    imageBox,
    inner,
    caramelSplash,
    chocolate,
    backlight,
} from './inspiration.module.scss';
import StarIcon from '../../assets/images/soplica-wedel/svg/star.svg';

import AbsoluteImage from './absolute-image';
import Backlight from './backlight';

interface IInspirationProps {
    className?: string;
}

const IMG_SRC = '/images/soplica-wedel-inspiration.png';

const Inspiration: React.FC<IInspirationProps> = ({ className = '' }) => {
    return (
        <div className={`${container} ${className}`}>
            <Backlight className={backlight} />
            <AbsoluteImage image="caramel-splash-three" className={caramelSplash} />
            <AbsoluteImage image="chocolate" className={chocolate} />
            <div className={inner}>
                <div className={header}>
                    <StarIcon />
                    <h2 className={title}>Soplica inspiruje</h2>
                </div>
                <div className={imageBox}>
                    <img src={IMG_SRC} alt="" />
                </div>
            </div>
        </div>
    );
};

export default Inspiration;
