import React from 'react';
import { getImage } from 'gatsby-plugin-image';

import {
    container,
    disclaimer,
    proposition,
    ratio,
    icon,
    imageBox,
    image,
} from './serving-card.module.scss';
import GlassIcon from '../../assets/images/soplica-wedel/svg/glass.svg';
import DrinkIcon from '../../assets/images/soplica-wedel/svg/drink.svg';
import { ImageDataLike } from '../../models/image-data-like.model';

import RatioImage from '../../components/atoms/ratio-image';
import ServingDisclaimer from './serving-disclaimer';

export type TWedelServingType = 'shot' | 'drink' | 'dessert' | 'coffee' | 'chocolate';

export interface IWedelServing {
    image: ImageDataLike;
    type: TWedelServingType;
    text: string;
}

interface IServingCardProps {
    className?: string;
    card: IWedelServing;
}

const ServingCard: React.FC<IServingCardProps> = ({ className = '', card }) => {
    const servingImage = getImage(card.image);
    const Icon = icons[card.type];

    return (
        <div className={`${container} ${className}`}>
            <div className={imageBox}>
                <RatioImage image={servingImage} className={image} ratioClass={ratio} />
                <ServingDisclaimer className={disclaimer} isWithUnderlay={true} />
            </div>
            <div className={proposition}>
                <Icon className={icon} /> <p>{card.text}</p>
            </div>
        </div>
    );
};

const icons: Record<TWedelServingType, React.SVGFactory> = {
    shot: GlassIcon,
    drink: DrinkIcon,
    dessert: GlassIcon,
    coffee: GlassIcon,
    chocolate: GlassIcon,
};

export default ServingCard;
