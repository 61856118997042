import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { imgBox } from './absolute-image.module.scss';

interface IAbsoluteImageProps {
    className?: string;
    image:
        | 'chocolate'
        | 'caramel'
        | 'chocolate-splash-one'
        | 'caramel-splash-one'
        | 'chocolate-splash-two'
        | 'caramel-splash-two'
        | 'chocolate-splash-three'
        | 'caramel-splash-three'
        | 'caramel-splash-four';
}

const AbsoluteImage: React.FC<IAbsoluteImageProps> = ({ className = '', image }) => {
    switch (image) {
        case 'caramel':
            return (
                <div className={`${className} ${imgBox}`}>
                    <StaticImage
                        src="../../assets/images/soplica-wedel/caramel.png"
                        alt=""
                        placeholder="blurred"
                    />
                </div>
            );
        case 'chocolate':
            return (
                <div className={`${className} ${imgBox}`}>
                    <StaticImage
                        src="../../assets/images/soplica-wedel/chocolate.png"
                        alt=""
                        placeholder="blurred"
                    />
                </div>
            );
        case 'caramel-splash-one':
            return (
                <div className={`${className} ${imgBox}`}>
                    <StaticImage
                        src="../../assets/images/soplica-wedel/caramel-splash-01.png"
                        alt=""
                        placeholder="blurred"
                    />
                </div>
            );
        case 'chocolate-splash-one':
            return (
                <div className={`${className} ${imgBox}`}>
                    <StaticImage
                        src="../../assets/images/soplica-wedel/chocolate-splash-01.png"
                        alt=""
                        placeholder="blurred"
                    />
                </div>
            );
        case 'caramel-splash-two':
            return (
                <div className={`${className} ${imgBox}`}>
                    <StaticImage
                        src="../../assets/images/soplica-wedel/caramel-splash-02.png"
                        alt=""
                        placeholder="blurred"
                    />
                </div>
            );
        case 'chocolate-splash-two':
            return (
                <div className={`${className} ${imgBox}`}>
                    <StaticImage
                        src="../../assets/images/soplica-wedel/chocolate-splash-02.png"
                        alt=""
                        placeholder="blurred"
                    />
                </div>
            );
        case 'caramel-splash-three':
            return (
                <div className={`${className} ${imgBox}`}>
                    <StaticImage
                        src="../../assets/images/soplica-wedel/caramel-splash-03.png"
                        alt=""
                        placeholder="blurred"
                    />
                </div>
            );
        case 'chocolate-splash-three':
            return (
                <div className={`${className} ${imgBox}`}>
                    <StaticImage
                        src="../../assets/images/soplica-wedel/chocolate-splash-03.png"
                        alt=""
                        placeholder="blurred"
                    />
                </div>
            );
        case 'caramel-splash-four':
            return (
                <div className={`${className} ${imgBox}`}>
                    <StaticImage
                        src="../../assets/images/soplica-wedel/caramel-splash-04.png"
                        alt=""
                        placeholder="blurred"
                    />
                </div>
            );
        default:
            return null;
    }
};

export default AbsoluteImage;
