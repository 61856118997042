import React from 'react';

import { backlight } from './backlight.module.scss';

interface IBacklightProps {
    className?: string;
}

const Backlight: React.FC<IBacklightProps> = ({ className = '' }) => {
    return <div className={`${backlight} ${className}`} />;
};

export default Backlight;
